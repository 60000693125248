import React, { ReactElement } from "react"
import SEO from "../../../components/SEO"
import T from "@mui/material/Typography"
import Skeleton from "../../../components/Skeleton"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import { useUser } from "../../../providers/UserProvider"
import Link from "../../../components/Link"
import { navigate } from "gatsby"
import Button from "../../../components/design-system/Button"
import { email } from "@ap-frontend/questions"
import ButtonWrapper from "../../../components/ButtonWrapper"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"

export const ApplicationSubmitted = (): ReactElement => {
  const { activity, signOut } = ApplicationSubmitted.useUser()

  if (activity === undefined) {
    // Still loading
    return (
      <>
        <T variant="h1" component="h1" role="display">
          <Skeleton />
          <T variant="body1">
            <Skeleton />
          </T>
          <T variant="h2" component="h2">
            <Skeleton />
          </T>
          <T variant="body1">
            <Skeleton />
          </T>
          <T variant="body2">
            <Skeleton />
          </T>
        </T>
      </>
    )
  }

  if (activity.submissionTimestamp === undefined) {
    // Not yet submitted
    navigate("/application", { replace: true })
    return <></>
  }

  return (
    <>
      <T variant="h1" component="h1">
        <CheckCircleOutlineIcon color="primary" fontSize="large" />
        Application submitted
      </T>
      <T variant="body1">
        {"We've sent you a confirmation email. "}
        If you do not receive it within 2 hours, contact{" "}
        <Link href={`mailto:${email}`}>{email}</Link>.
      </T>
      <T variant="h4" component="h2">
        Photo and transcripts
      </T>
      <T variant="body2">
        {
          "If you told us in your application that you'll send a photo, a high school transcript or a university transcript, email it to "
        }
        <Link href={`mailto:${email}`}>{email}</Link>.
      </T>
      <T variant="body2">When you email us, please include your:</T>
      <List component="ul" className="MuiList-unordered">
        <ListItem>
          <T variant="body2">full name</T>
        </ListItem>
        <ListItem>
          <T variant="body2">UCAS personal ID number</T>
        </ListItem>
        <ListItem>
          <T variant="body2">the course you applied for</T>
        </ListItem>
      </List>
      <T variant="h4" component="h2">
        What happens next
      </T>
      <T variant="body2">
        {
          "We'll review your application and contact you if we need more information."
        }
      </T>
      <ButtonWrapper>
        <Button onClick={() => signOut()}>Sign out</Button>
      </ButtonWrapper>
    </>
  )
}

ApplicationSubmitted.useUser = useUser

/**
 * The application submission page renders a page to confirm the submission of an application
 */
const ApplicationSubmittedPage = (): ReactElement => {
  return (
    <>
      <SEO title="Application Submitted" />
      <ApplicationSubmitted />
    </>
  )
}

export default ApplicationSubmittedPage
